.video-js .vjs-current-time,
.video-js .vjs-duration {
  display: flex;
  align-items: center;
}

.video-js .vjs-control-bar {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.5) 30%,
    transparent 100%
  );
}

.video-js .vjs-control-bar .vjs-control[role='button'] {
  appearance: none;
}

.video-js .vjs-control-bar .vjs-control[disabled='disabled'],
.video-js .vjs-control-bar .vjs-control.vjs-playback-rate {
  display: none;
}

.video-js {
  .vjs-ygb-logo {
    position: absolute;
    top: 14px;
    right: 10px;
    width: 85px;
    opacity: 0.5;
  }

  &.vjs-fullscreen .vjs-ygb-logo {
    display: none;
  }
}

// disable forward/backward button
.video-js .vjs-b-f-b,
// .video-js .vjs-b-p-b,
.video-js .vjs-b-r-b {
  display: none;
}

.video-js .vjs-mobile-center-spacer {
  display: none;
}

// .video-js {
//   .vjs-quality-button {
//     &:before {
//       content: '\e905';
//       font-family: nuevo;
//       font-size: 20px;
//     }

//     .quality-span {
//       position: absolute;
//       top: -5%;
//       right: 0;
//       font-size: 12px;
//       line-height: 1;
//     }
//   }
// }
